import React, { useState } from "react";

function AddReview({ customerReviews, setCustomerReviews }) {
  const [name, setName] = useState("");
  const [rating, setRating] = useState("");
  const [comment, setComment] = useState("");
  const [desc, setDesc] = useState("");

  const addReview = (review) => {
    setCustomerReviews([...customerReviews, review]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newReview = {
      name,
      rating: parseInt(rating),
      comment,
      desc,
    };

    addReview(newReview);

    // Clear the form fields
    setName("");
    setRating("");
    setComment("");
    setDesc("");
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{
            width: "100%",
            marginBottom: "10px",
            height: "35px",
          }}
        />
        <input
          type="number"
          name="rating"
          placeholder="Rating"
          value={rating}
          onChange={(e) => setRating(e.target.value)}
          style={{
            width: "100%",
            marginBottom: "10px",
            height: "35px",
          }}
        />
        <input
          type="text"
          name="comment"
          placeholder="Comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          style={{
            width: "100%",
            marginBottom: "10px",
            height: "35px",
          }}
        />
        <textarea
          name="desc"
          placeholder="Description"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          style={{
            width: "100%",
            height: "200px",
            columns: "2",
            marginBottom: "10px",

            /* Add more styles as needed */
          }}
        />

        <button type="submit">Add Review</button>
      </form>

      {customerReviews.map((review, index) => (
        <div key={index}>
          <h2>{index + 1}.</h2>
          <p>Name: {review.name}</p>
          <p>Rating: {review.rating}</p>
          <p>Comment: {review.comment}</p>
          <p>Description: {review.desc}</p>
        </div>
      ))}
    </div>
  );
}

export default AddReview;
