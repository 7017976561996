import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "axios";
import { DarkModeContextProvider } from "./context/darkModeContext";
import { AuthContextProvider } from "./context/AuthContext";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import { ToastContainer } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL
axios.defaults.withCredentials = true;

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <Provider store={store}>

        <DarkModeContextProvider>
          <App />
          <ToastContainer />
        </DarkModeContextProvider>
      </Provider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
