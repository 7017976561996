import "../newHotel/newHotel.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import * as React from "react";
import { Button } from "@mui/material";
import { toast } from "react-toastify";

const NewHero = ({ title }) => {
  const [uploaded, setUploaded] = useState("");
  const [progress, setProgress] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setUploaded("uploadreq");

    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("images", selectedFiles[i]);
    }

    try {
      const response = await axios.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });

      setUploaded("uploaded");
      setUploadedImages(response.data.imageUrls);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const HandleCreateHotel = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post("/create-hero", {
        Images: uploadedImages,
      });
      if (res.data.success) {
        setProgress(null);
        setSelectedFiles([]);
        setUploaded(null);
        setUploadedImages([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleDelete = async () => {
    try {
      const res = await axios.delete("/delete-hero");

      if (res.data.success) {
        alert("all Images of Slider has been deleted!");
      }
      toast.success('Item deleted successfully');
    } catch (error) {
      console.error(error);
      toast.error('Error deleting item');
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <Button variant="contained" onClick={HandleDelete}>
          Delete All
        </Button>
        <div className="bottom">
          <div className="lefthotel">
            {uploadedImages.map((imageUrl, index) => (
              <li key={index}>
                <img
                  src={
                    imageUrl
                      ? imageUrl
                      : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                  }
                  alt={`Uploaded Image ${index}`}
                />
              </li>
            ))}
          </div>
          <div className="righthotel">
            <div className="hotelForm">
              <div className="formInput">
                <label>Images:</label>
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  id="img1"
                  required
                />
              </div>

              <div style={{ width: "70px", height: "70px" }}>
                <CircularProgressbar value={progress} text={`${progress}%`} />
              </div>

              <button
                className="addProductButton"
                onClick={handleUpload}
                disabled={uploaded === "uploaded"}>
                Upload Files
              </button>
              <button
                className="addProductButton"
                disabled={uploaded !== "uploaded"}
                onClick={HandleCreateHotel}>
                Create Product
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHero;
