import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Axios from '../../api'; // Make sure to import Axios

import "./country.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import CountryList from "../../components/country-data/countrydata";

const CountryData = () => {
    const [countryBaseList, setcountryBaseList] = useState([]);
    const fetchData = async () => {
        try {
            const response = await Axios.get('/api/countries/get-allcountry');
            setcountryBaseList(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                <CountryList data={countryBaseList} fetchData={fetchData} />
            </div>
        </div>
    );
};

export default CountryData;
