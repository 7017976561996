import React from "react";
import TagsInput from "./TagsInput";

const InputChip = ({ setMentionTags, label }) => {
  function handleSelecetedTags(items) {
    setMentionTags(items);
  }
  return (
    <div className="App">
      <TagsInput
        selectedTags={handleSelecetedTags}
        fullWidth
        variant="outlined"
        id="tags"
        name="tags"
        placeholder="add Tags"
        label={label}
      />
    </div>
  );
};

export default InputChip;
