import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';
import './FooterManagement.scss';
import Axios from '../../api';

const FooterManagement = () => {
    const [footerData, setFooterData] = useState({
        officeBranches: [
            {
                location: '',
                address: '',
            },
        ],
        contactInfo: [
            {
                label: '',
                number: '',
                address: '',
            },
        ],
    });

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setError(null);

        Axios.get('/api/footer/get-footer')
            .then((response) => {
                setFooterData(response.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handleFormSubmit = (e) => {
        e.preventDefault();

        // Form validation here, e.g., check if required fields are filled

        setIsLoading(true);
        setError(null);

        Axios.put('/api/footer/update-footer', footerData)
            .then(() => {
                alert('Footer data updated successfully');
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleInputChange = (e, section, index, field) => {
        const newData = { ...footerData };
        newData[section][index][field] = e.target.value;
        setFooterData(newData);
    };

    const addSection = (section) => {

        const newData = { ...footerData };
        if (!newData[section]) {
            newData[section] = [];
        }

        newData[section].push({
            location: '',
            address: '',
        });
        setFooterData(newData);
    };

    const removeSection = (section, index) => {
        const newData = { ...footerData };
        if (!newData[section]) {
            newData[section] = [];
        }

        newData[section].splice(index, 1);
        setFooterData(newData);
    };

    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                <div className="country-form">
                    <h2>Footer Management</h2>
                    {error && <p className="error-message">An error occurred: {error.message}</p>}
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        <form onSubmit={handleFormSubmit}>
                            <h3>Office Branches</h3>
                            {footerData?.officeBranches?.map((branch, index) => (
                                <div key={index} className="section-container">
                                    <h3>Office Branch {index + 1}</h3>
                                    <label htmlFor={`location-${index}`}>Location:</label>
                                    <input
                                        type="text"
                                        id={`location-${index}`}
                                        placeholder="Enter location"
                                        value={branch.location}
                                        onChange={(e) => handleInputChange(e, 'officeBranches', index, 'location')}
                                    />
                                    <label htmlFor={`address-${index}`}>Address:</label>
                                    <input
                                        type="text"
                                        id={`address-${index}`}
                                        placeholder="Enter address"
                                        value={branch.address}
                                        onChange={(e) => handleInputChange(e, 'officeBranches', index, 'address')}
                                    />
                                    <button type="button" onClick={() => removeSection('officeBranches', index)}>
                                        Remove
                                    </button>
                                </div>
                            ))}
                            <button type="button" onClick={() => addSection('officeBranches')}>
                                Add Office Branch
                            </button>

                            <h3>Contact Info</h3>
                            {footerData?.contactInfo?.map((info, index) => (
                                <div key={index} className="section-container">
                                    <h3>Contact Info {index + 1}</h3>
                                    <label htmlFor={`label-${index}`}>Label:</label>
                                    <input
                                        type="text"
                                        id={`label-${index}`}
                                        placeholder="Enter label"
                                        value={info.label}
                                        onChange={(e) => handleInputChange(e, 'contactInfo', index, 'label')}
                                    />
                                    <label htmlFor={`number-${index}`}>Number:</label>
                                    <input
                                        type="text"
                                        id={`number-${index}`}
                                        placeholder="Enter number"
                                        value={info.number}
                                        onChange={(e) => handleInputChange(e, 'contactInfo', index, 'number')}
                                    />
                                    <label htmlFor={`address-${index}`}>Address:</label>
                                    <input
                                        type="text"
                                        id={`address-${index}`}
                                        placeholder="Enter address"
                                        value={info.address}
                                        onChange={(e) => handleInputChange(e, 'contactInfo', index, 'address')}
                                    />
                                    <button type="button" onClick={() => removeSection('contactInfo', index)}>
                                        Remove
                                    </button>
                                </div>
                            ))}
                            <button type="button" onClick={() => addSection('contactInfo')}>
                                Add Contact Info
                            </button>

                            <button type="submit">Save Changes</button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FooterManagement;
