import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Axios from '../../api';
import { toast } from 'react-toastify';
import "./countrydata.scss";
import { Link } from "react-router-dom";

const columns = [
  { field: "country", headerName: "Country", flex: 1, minWidth: 150, maxWidth: 300 },
  { field: "overviewlefth", headerName: "Overview Left", flex: 1, minWidth: 150, maxWidth: 300 },
  { field: "overviewrighth", headerName: "Overview Right", flex: 1, minWidth: 150, maxWidth: 300 },
  { field: "itineryheading", headerName: "Itinerary Heading", flex: 1, minWidth: 150, maxWidth: 300 },
];



const CountryList = ({ data, fetchData }) => {
  const [list, setList] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedListingItem, setSelectedListingItem] = useState(null);

  const handleView = (row) => {
    setSelectedData(row);
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await Axios.delete(`/api/countries/delete/${id}`);
      const updatedList = list.filter((item) => item.__id !== id);
      setList(updatedList);
      setModalOpen(false);
      fetchData();
      setSelectedData(null);
      toast.success('Country deleted successfully!', {
        position: 'top-right',
        autoClose: 3000,
      });
    } catch (error) {
      console.log(error);

      toast.error('An error occurred while deleting the country.', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };



  const handleDeleteListingItem = async (listingItem) => {
    if (selectedData && listingItem) {
      try {
        await Axios.delete(`/api/countries/delete-listing-item/${selectedData._id}/${listingItem._id}`);

        const updatedListing = selectedData.listing.filter(item => item._id !== listingItem._id);
        setSelectedData({
          ...selectedData,
          listing: updatedListing,
        });
        toast.success('Listing item deleted successfully!', {
          position: 'top-right',
          autoClose: 3000,
        });
      } catch (error) {
        console.error("Error deleting listing item:", error);

        toast.error('An error occurred while deleting the listing item.', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    }
  };



  const listingColumns = [
    { field: "image", headerName: "Image", flex: 1, minWidth: 150, maxWidth: 300 },
    { field: "heading", headerName: "Heading", flex: 1, minWidth: 150, maxWidth: 300 },
    { field: "text", headerName: "Text", flex: 1, minWidth: 150, maxWidth: 300 },
    { field: "destination", headerName: "Destination", flex: 1, minWidth: 150, maxWidth: 300 },
    { field: "duration", headerName: "Duration", flex: 1, minWidth: 150, maxWidth: 300 },
    { field: "priceperperson", headerName: "Price per Person", flex: 1, minWidth: 150, maxWidth: 300 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleDeleteListingItem(params.row)}
          >
            Delete
          </Button>
        );
      },
    },
  ];
  useEffect(() => {
    setList(data);
  }, [data]);

  return (
    <>
      <div className="datatable">
        <div className="datatableTitle">
          <Link to={`/country/addcountry`} className="link">
            Add New
          </Link>
        </div>
        <DataGrid
          rows={list}
          columns={columns}
          pageSize={9}
          rowsPerPageOptions={[9]}
          onRowClick={(params) => handleView(params.row)}
          checkboxSelection
          getRowId={(row) => row._id}
        />
      </div>

      <Dialog open={isModalOpen} onClose={() => setModalOpen(false)} PaperProps={{
        style: { width: "80vw", maxWidth: "none" }
      }}>
        <DialogTitle>Country Details</DialogTitle>
        <DialogContent >
          {selectedData && (
            <div className="selectedData">
              <p> <strong>Country :</strong> {selectedData.country}</p>
              <p><strong>Overview Left :</strong> {selectedData.overviewlefth}</p>
              <p><strong>Overview Right :</strong> {selectedData.overviewrighth}</p>
              <p><strong>Itinerary Heading :</strong> {selectedData.itineryheading}</p>
              {selectedData.listing.length > 0 ? (
                <div style={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={selectedData.listing}
                    columns={listingColumns}
                    pageSize={9}
                    rowsPerPageOptions={[9]}
                    getRowId={(row) => row._id}
                  />
                </div>
              ) : (
                <p>No itineraries available for this country.</p>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>

          <div className="datatableTitle">
            <Link to={`/country/editcountry/${selectedData?._id}`} className="link">
              <Button variant="contained" color="secondary">
                Edit
              </Button>
            </Link>
          </div>
          <Button onClick={() => handleDelete(selectedData._id)} variant="contained" color="secondary">
            Delete Country
          </Button>
          <Button onClick={() => setModalOpen(false)} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CountryList;
