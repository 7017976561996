import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Axios from '../../api'; // Make sure to import Axios

import "./country.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import CountryList from "../../components/country-data/countrydata";
import CountryPage from "../../components/country-data/country-page";

const AddCountry = () => {
    const [bespokeList, setBespokeList] = useState([]);
    const fetchData = async () => {
        try {
            const response = await Axios.get('/travel/get-all-travelforms');
            setBespokeList(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {


        // Call the async function
        fetchData();
    }, []); // Empty dependency array means this effect runs once on component mount

    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                <CountryPage data={bespokeList} fetchData={fetchData} />
            </div>
        </div>
    );
};

export default AddCountry;
