import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Axios from '../../api';
import { toast } from "react-toastify";

const ItineraryTripTable = ({ data, fetchData }) => {
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();

    const handleDelete = async (id) => {
        try {
            await Axios.delete(`/api/itinerary/trips/${id}`);
            let updatedData = data.filter((item) => item._id !== id);
            setTableData(updatedData);
            fetchData();
            toast.success('Item deleted successfully');
        } catch (error) {
            console.error(error);
            toast.error('Error deleting item');
        }
    };

    const columns = [
        { field: 'tripTitle', headerName: 'Trip Title', flex: 1, minWidth: 200 },
        { field: 'tripSubtitle', headerName: 'Trip Subtitle', flex: 1, minWidth: 200 },
        { field: 'tripImage', headerName: 'Trip Image', flex: 1, minWidth: 200, renderCell: (params) => <img src={params.value} alt="Trip" width="50" /> },
        { field: 'mapIframeSrc', headerName: 'Map Iframe Src', flex: 1, minWidth: 200 },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div className="cellAction">
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDelete(params.row._id)}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate(`/itinerary-trip/${params.row._id}`)}
                    >
                        Edit
                    </Button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        setTableData(data);
    }, [data]);

    return (
        <div className="datatable">
            <div className="datatableTitle">
                <Link to={`/itinerary-trip/add`} className="link">
                    Add New Itinerary
                </Link>
            </div>
            <DataGrid
                rows={tableData}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row._id}
                checkboxSelection
            />
        </div>
    );
};

export default ItineraryTripTable;
