// store.js

import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { combineReducers } from 'redux';
import enquiriesReducer from './reducer/enquiry.jsx';

const rootReducer = combineReducers({
    enquiriesReducer
});

const store = createStore(
    rootReducer,
    applyMiddleware(thunkMiddleware)
);

export default store;
