import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import useFetch from "../../hooks/useFetch";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";

const Home = () => {
  const { data, loading, error } = useFetch("/users?stats=true");
  const MONTHS = useMemo(
    () => [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  const [userStats, setUserStats] = useState([]);

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      // Check if data is an array and not empty
      const sortedData = data
        .filter(item => item && typeof item._id === 'number') // Filter out null or undefined values and items without _id
        .sort((a, b) => a._id - b._id);

      const updatedUserStats = sortedData.map((item) => ({
        name: MONTHS[item._id - 1],
        Total: item.total,
      }));

      setUserStats(updatedUserStats);
    } else {
      // Handle the case when data is null, undefined, or an empty array
      setUserStats([]);
    }
  }, [data]);
  console.log(userStats);
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="user" />
          <Widget type="order" />
          <Widget type="earning" />
          <Widget type="balance" />
        </div>
      </div>
    </div>
  );
};

export default Home;
