import React, { useEffect, useState } from 'react';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';
import "./travel-package.scss"
import Axios from '../../api';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TravelEditPackageForm = () => {
    const { id } = useParams();
    const initialPackDetail = {
        startDate: null,
        endDate: null,
        flexiablechecked: false,
        flexiabledate: "",
        price: '',
        singleSupplementPrice: '',
        status: false,
        availability: '',
        activity_level: ''
    };

    const [formData, setFormData] = useState({
        country: '',
        packDetails: [initialPackDetail]
    });

    const handleChange = (e, index) => {
        const { name, value, type, checked } = e.target;
        const updatedPackDetails = [...formData.packDetails];
        const fieldName = name.split('-')[0]; // Extract field name from the input name

        updatedPackDetails[index] = {
            ...updatedPackDetails[index],
            [fieldName]: type === 'checkbox' ? checked : value,
        };

        if (fieldName === 'flexiablechecked') {
            updatedPackDetails[index] = {
                ...updatedPackDetails[index],
                flexiabledate: checked ? '' : updatedPackDetails[index].flexiabledate
            };
        }

        setFormData({
            ...formData,
            packDetails: updatedPackDetails,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await Axios.put(`/api/package/travel-packages/${id}`, formData);
            if (response.status === 200) {
                toast.success('Itinerary updated successfully', { position: toast.POSITION.TOP_RIGHT });
            } else {
                toast.error('Failed to update the itinerary.', { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred while updating the itinerary.', { position: toast.POSITION.TOP_RIGHT });
        }
    };

    const handleAddPackDetail = () => {
        setFormData({
            ...formData,
            packDetails: [...formData.packDetails, { ...initialPackDetail }]
        });
    };

    const handleRemovePackDetail = (index) => {
        const updatedPackDetails = [...formData.packDetails];
        updatedPackDetails.splice(index, 1);
        setFormData({
            ...formData,
            packDetails: updatedPackDetails,
        });
    };

    const convertToDate = (dateString) => {
        try {
            if (!dateString) return null;  // Return null if dateString is empty

            const date = new Date(dateString);
            return isNaN(date.getTime()) ? null : date;
        } catch (error) {
            console.error('Error parsing date:', error);
            return null;
        }
    };

    useEffect(() => {
        async function fetchItineraryDetails() {
            try {
                const response = await Axios.get(`/api/package/get-travel-packages/${id}`);
                if (response.status === 200) {
                    const itineraryData = response.data;
                    itineraryData.packDetails.forEach((pack) => {
                        pack.startDate = convertToDate(pack.startDate);
                        pack.endDate = convertToDate(pack.endDate);
                    });
                    setFormData(itineraryData);
                } else {
                    toast.error('Failed to fetch itinerary details.', { position: toast.POSITION.TOP_RIGHT });
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('An error occurred while fetching details.', { position: toast.POSITION.TOP_RIGHT });
            }
        }

        fetchItineraryDetails();
    }, [id]);

    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                <form onSubmit={handleSubmit} className="form-container">
                    <h2>Itinerary Table Management</h2>
                    <div className="form-group">
                        <label>Country</label>
                        <input
                            type="text"
                            name="country"
                            value={formData.country}
                            onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                            required
                        />

                        {formData.packDetails.map((pack, index) => (
                            <div key={index} className="pack-details">
                                <h3>Pack Detail {index + 1}</h3>
                                <label>Start Date</label>
                                <DatePicker
                                    name={`startDate-${index}`}
                                    selected={pack.startDate}
                                    onChange={(date) => {
                                        const updatedPackDetails = [...formData.packDetails];
                                        updatedPackDetails[index].startDate = date;
                                        setFormData({
                                            ...formData,
                                            packDetails: updatedPackDetails
                                        });
                                    }}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Select start date"
                                />

                                <label>End Date</label>
                                <DatePicker
                                    name={`endDate-${index}`}
                                    selected={pack.endDate}
                                    onChange={(date) => {
                                        const updatedPackDetails = [...formData.packDetails];
                                        updatedPackDetails[index].endDate = date;
                                        setFormData({
                                            ...formData,
                                            packDetails: updatedPackDetails
                                        });
                                    }}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Select end date"
                                />

                                <div>
                                    <label>Flexible Date</label>
                                    <input
                                        type="checkbox"
                                        name={`flexiablechecked-${index}`}
                                        checked={pack.flexiablechecked}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                    {pack.flexiablechecked && (
                                        <input
                                            type="text"
                                            name={`flexiabledate-${index}`}
                                            value={pack.flexiabledate}
                                            onChange={(e) => handleChange(e, index)}
                                            placeholder='Flexible date'
                                        />
                                    )}
                                </div>

                                <label>Price</label>
                                <input
                                    type="number"
                                    name={`price-${index}`}
                                    value={pack.price}
                                    onChange={(e) => handleChange(e, index)}
                                    required
                                />

                                <label>Single Supplement Price</label>
                                <input
                                    type="number"
                                    name={`singleSupplementPrice-${index}`}
                                    value={pack.singleSupplementPrice}
                                    onChange={(e) => handleChange(e, index)}
                                    required
                                />

                                <label>Status</label>
                                <input
                                    type="checkbox"
                                    name={`status-${index}`}
                                    checked={pack.status}
                                    onChange={(e) => handleChange(e, index)}
                                />

                                <label>Availability</label>
                                <input
                                    type="text"
                                    name={`availability-${index}`}
                                    value={pack.availability}
                                    onChange={(e) => handleChange(e, index)}
                                    required
                                />

                                <label>Activity Level</label>
                                <input
                                    type="text"
                                    name={`activity_level-${index}`}
                                    value={pack.activity_level}
                                    onChange={(e) => handleChange(e, index)}
                                    required
                                />

                                <button type="button" onClick={() => handleRemovePackDetail(index)}>
                                    Remove Pack Detail
                                </button>
                            </div>
                        ))}

                        <button type="button" onClick={handleAddPackDetail}>
                            Add Pack Detail
                        </button>

                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TravelEditPackageForm;
