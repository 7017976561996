import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';
import Axios from '../../api';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TravelPackageForm = () => {
    const initialPackDetail = {
        startDate: null,
        endDate: null,
        flexiablechecked: false,
        flexiabledate: "",
        price: '',
        singleSupplementPrice: '',
        status: false,
        availability: '',
        activity_level: ''
    };

    const [formData, setFormData] = useState({
        country: '',
        packDetails: [initialPackDetail]
    });


    useEffect(() => {
        console.log(formData, "fetching details...");
    }, [formData]);

    const handleChange = (e, index) => {
        const { name, value, type, checked } = e.target;
        const formattedValue = name === 'startDate' || name === 'endDate' ? value : value;
        const updatedPackDetails = [...formData.packDetails];
        updatedPackDetails[index] = {
            ...updatedPackDetails[index],
            [name]: type === 'checkbox' ? checked : formattedValue,
        };

        if (name === 'flexiablechecked') {
            if (checked) {
                updatedPackDetails[index] = {
                    ...updatedPackDetails[index],
                    flexiabledate: ''
                };
            } else {
                updatedPackDetails[index] = {
                    ...updatedPackDetails[index],
                    flexiabledate: ""
                };
            }
        }

        setFormData({
            ...formData,
            packDetails: updatedPackDetails,
        });
    };

    const handleAddPackDetail = () => {
        setFormData({
            ...formData,
            packDetails: [...formData.packDetails, { ...initialPackDetail }]
        });
    };

    const handleRemovePackDetail = (index) => {
        const updatedPackDetails = [...formData.packDetails];
        updatedPackDetails.splice(index, 1);
        setFormData({
            ...formData,
            packDetails: updatedPackDetails,
        });
    };

    const handleCountryChange = (e) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            country: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formattedFormData = {
                ...formData,
                packDetails: formData.packDetails.map((pack) => ({
                    ...pack,
                    startDate: pack.startDate,
                    endDate: pack.endDate
                })),
            };
            console.log(formattedFormData);
            const response = await Axios.post('/api/package/travel-packages', formattedFormData);
            if (response.status >= 200 && response.status < 300) {
                setFormData({
                    country: '',
                    packDetails: [initialPackDetail]
                });
                toast.success('Data submitted successfully', { position: toast.POSITION.TOP_RIGHT });
            } else {
                toast.error('Server error. Please try again later.', { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred. Please try again later.', { position: toast.POSITION.TOP_RIGHT });
        }
    };

    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                <form onSubmit={handleSubmit} className="form-container">
                    <h2>Itinerary Table Management</h2>
                    <div className="form-group">
                        <label>Country</label>
                        <input
                            type="text"
                            name="country"
                            value={formData.country}
                            onChange={(e) => handleCountryChange(e)}
                            required
                        />

                        {formData.packDetails.map((pack, index) => (
                            <div key={index} className="pack-details">
                                <h3>Pack Detail {index + 1}</h3>
                                <label>Start Date</label>
                                <DatePicker
                                    name="startDate"
                                    selected={pack.startDate}
                                    onChange={(date) => handleChange({ target: { name: 'startDate', value: date } }, index)}
                                    dateFormat="dd-MM-yyyy"
                                />

                                <label>End Date</label>
                                <DatePicker
                                    name="endDate"
                                    selected={pack.endDate}
                                    onChange={(date) => handleChange({ target: { name: 'endDate', value: date } }, index)}
                                    dateFormat="dd-MM-yyyy"
                                />
                                <div>
                                    <label>flexiablecheck Status</label>
                                    <input
                                        className='checkbox'
                                        type="checkbox"
                                        name="flexiablechecked"
                                        checked={pack.flexiablechecked}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                    {
                                        pack.flexiablechecked && <input
                                            type="text"
                                            name="flexiabledate"
                                            value={pack.flexiabledate}
                                            onChange={(e) => handleChange(e, index)}
                                            placeholder='Flexiable date'
                                        />
                                    }
                                </div>

                                <label>Price</label>
                                <input
                                    type="number"
                                    name="price"
                                    value={pack.price}
                                    onChange={(e) => handleChange(e, index)}
                                    required
                                />

                                <label>Single Supplement Price</label>
                                <input
                                    type="number"
                                    name="singleSupplementPrice"
                                    value={pack.singleSupplementPrice}
                                    onChange={(e) => handleChange(e, index)}
                                    required
                                />

                                <label>Status</label>
                                <input
                                    className='checkbox'
                                    type="checkbox"
                                    name="status"
                                    checked={pack.status}
                                    onChange={(e) => handleChange(e, index)}
                                />

                                <label>Availability</label>
                                <input
                                    type="text"
                                    name="availability"
                                    value={pack.availability}
                                    onChange={(e) => handleChange(e, index)}
                                    required
                                />

                                <label>Activity Level</label>
                                <input
                                    type="text"
                                    name="activity_level"
                                    value={pack.activity_level}
                                    onChange={(e) => handleChange(e, index)}
                                    required
                                />

                                <button type="button" className='btn' onClick={() => handleRemovePackDetail(index)}>
                                    Remove Pack Detail
                                </button>
                            </div>
                        ))}

                        <button type="button" className='btn' onClick={handleAddPackDetail}>
                            Add Pack Detail
                        </button>

                        <button type="submit" className='btn' >Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TravelPackageForm;
