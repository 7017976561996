import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Axios from '../../api';
import { toast } from "react-toastify";



const TravelPackageTable = ({ data, fetchData }) => {
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate()

    const handleDelete = async (id) => {
        try {
            await Axios.delete(`/api/package/travel-packages/${id}`);
            let updatedData = data.filter((item) => item._id !== id);
            setTableData(updatedData);
            fetchData();
            toast.success('Item deleted successfully');
        } catch (error) {
            console.error(error);
            toast.error('Error deleting item');
        }
    };

    const columns = [
        { field: 'country', headerName: 'Country name', flex: 1, minWidth: 200 },
        { field: 'startDate', headerName: 'Start Date', flex: 1, minWidth: 150 },
        { field: 'endDate', headerName: 'End Date', flex: 1, minWidth: 150 },
        { field: 'price', headerName: 'Price', flex: 1, minWidth: 150 },
        {
            field: 'singleSupplementPrice',
            headerName: 'Single Supplement Price',
            flex: 1,
            minWidth: 100,
        },
        { field: 'status', headerName: 'Status', flex: 1, minWidth: 100 },
        { field: 'availability', headerName: 'Availability', flex: 1, minWidth: 100 },
        { field: 'activity_level', headerName: 'Activity-Level', flex: 1, minWidth: 100 },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div className="cellAction">
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDelete(params.row.country_id)}
                    >
                        Delete
                    </Button>
                    <Button variant="outlined"
                        color="secondary" onClick={() => navigate(`/itinerary/${params.row.country_id}`)} >
                        Edit
                    </Button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        setTableData(data)
    }, [data])


    return (
        <div className="datatable">
            <div className="datatableTitle">
                <Link to={`/itinerary/add`} className="link">
                    Add New Itinerary
                </Link>
            </div>
            <DataGrid
                rows={tableData}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row._id}
                checkboxSelection
            />

        </div>
    );
};

export default TravelPackageTable;
