import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Axios from '../../api'; // Make sure to import Axios

import "./enquiry-form.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import EnquiryList from "../../components/enquiry-form/enquiry-form";

const EnquiryForm = () => {
  const [enquiryList, setEnquiryList] = useState([]);
  const fetchData = async () => {
    try {
      const response = await Axios.get('/api/enquiryform/submissions');
      setEnquiryList(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <EnquiryList data={enquiryList} fetchData={fetchData} />
      </div>
    </div>
  );
};

export default EnquiryForm;
