import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Axios from '../../api';

import "./itinerary.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import EnquiryFormList from "../../components/enquirytable/enquirytable";
import TravelPackageForm from "../../components/travelpackage/travel-package";
import TravelPackageTable from "../../components/travelpackage/travelpackage-table";

const Itinerary = () => {
  const [enquiryList, setEnquiryList] = useState([]);
  const fetchData = async () => {
    try {
      const response = await Axios.get('/api/package/travel-packages');
      setEnquiryList(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <TravelPackageTable data={enquiryList} fetchData={fetchData} />
      </div>
    </div>
  );
};

export default Itinerary;
