export const hotelInputs = [
  {
    id: "shortTitle",
    label: "shortTitle",
    type: "text",
    placeholder: "I phone 14 pro max",
  },
  {
    id: "longTitle",
    label: "longTitle",
    type: "text",
    placeholder: "Resistance Tube (Multicolor)",
  },
  {
    id: "mrp",
    label: "mrp",
    type: "Number",
    placeholder: "499",
  },
  {
    id: "cost",
    label: "cost",
    type: "Number",
    placeholder: "49",
  },
  {
    id: "discount",
    label: "discount",
    type: "Number",
    placeholder: "90",
  },

  {
    id: "description",
    label: "description",
    type: "text",
    placeholder: "description",
  },
  // {
  //   id: "colorOfProducts",
  //   label: "colorOfProducts (put , between two colors *)",
  //   type: "text",
  //   placeholder: "colorOfProducts",
  // },
  // {
  //   id: "sizeOfProducts",
  //   label: "sizeOfProducts (put , between two colors *)",
  //   type: "text",
  //   placeholder: "sizerOfProducts",
  // },
];
