import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Axios from '../../api'; // Make sure to import Axios

import "./enquiry.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import EnquiryFormList from "../../components/enquirytable/enquirytable";

const Enquiry = () => {
  const [enquiryList, setEnquiryList] = useState([]);
  const fetchData = async () => {
    try {
      const response = await Axios.get('/enquiry/getenquiries');
      setEnquiryList(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {


    // Call the async function
    fetchData();
  }, []); // Empty dependency array means this effect runs once on component mount

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <EnquiryFormList data={enquiryList} fetchData={fetchData} />
      </div>
    </div>
  );
};

export default Enquiry;
