export const hotelColumns = [
  { field: "_id", headerName: "ID", width: 250 },
  {
    field: "shortTitle",
    headerName: "Title",
    width: 150,
  },
  {
    field: "longTitle",
    headerName: "Subtitle",
    width: 100,
  },
  {
    field: "mrp",
    headerName: "Mrp",
    width: 100,
  },
  {
    field: "cost",
    headerName: "Cost",
    width: 100,
  },
  {
    field: "discount",
    headerName: "Discount",
    width: 100,
  },
];
