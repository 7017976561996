import "./enquiry-form.scss";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Axios from "../../api";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";



const columns = [
  {
    field: "fullName",
    headerName: "Full Name",
    minWidth: 150,
    maxWidth: 300,
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.firstName} ${params.row.lastName}`;
    },
  },
  {
    field: "email",
    headerName: "Email",
    minWidth: 200,
    maxWidth: 400,
    flex: 3,
    renderCell: (params) => {
      return <a href={`mailto:${params.value}`}>{params.value}</a>;
    },
  },
  {
    field: "contactNumber", headerName: "Contact Number", flex: 1, minWidth: 150,
    maxWidth: 300,
  },
  {
    field: "nationality", headerName: "Nationality", flex: 1, minWidth: 150,
    maxWidth: 300,
  },
  {

    field: "submissionDate",
    headerName: "SubmissionDate",
    flex: 1,
    minWidth: 150,
    maxWidth: 300,
    valueGetter: (params) => {
      return `${params.row.submissionDate}`;
    },
  },
  {
    field: "age", headerName: "Age", flex: 1, minWidth: 150,
    maxWidth: 300,
  },
  {
    field: "message", headerName: "Message", flex: 1, minWidth: 150,
    maxWidth: 300,
  }
];


const EnquiryList = ({ data, fetchData }) => {

  const [list, setList] = useState(data);
  const [selectedData, setSelectedData] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);


  const handleDelete = async (id) => {
    try {
      await Axios.delete(`/api/enquiryform/submissions/${id}`);
      setList(list.filter((item) => item._id !== id));
      setSelectedData(null);
      fetchData();
      toast.success('Item deleted successfully');
    } catch (error) {
      console.error(error);
      toast.error('Error deleting item');
    }
  };

  const handleView = (row) => {
    setSelectedData(row);
    setModalOpen(true);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="viewButton" onClick={() => handleView(params.row)}>View</div>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row._id)}>
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="datatable">
        <DataGrid className="datagrid"
          rows={data}
          columns={columns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
          getRowId={(row) => row._id} />
      </div>

      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Enquiry Details</DialogTitle>
        <DialogContent>
          {selectedData && (
            <div className="selectedData">
              <p><strong>Full Name : </strong> {`${selectedData.firstName} ${selectedData.lastName}`}</p>
              <p><strong>Email : </strong><a href={`mailto:${selectedData.email}`}>{selectedData.email}</a></p>
              <p><strong>Contact Number : </strong> {selectedData.contactNumber}</p>
              <p><strong>Nationality : </strong> {`${selectedData.nationality}`}</p>
              <p><strong>SubmissionDate : </strong> {selectedData.submissionDate}</p>
              <p><strong>Age : </strong> {selectedData.age}</p>
              <p><strong>Message : </strong> {selectedData.message}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EnquiryList;
