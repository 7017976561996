import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Axios from '../../api'; // Make sure to import Axios

import "./bespoke.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import BespokeList from "../../components/bespoketable/bespoketable";

const Bespoke = () => {
    const [bespokeList, setBespokeList] = useState([]);
    const fetchData = async () => {
        try {
            const response = await Axios.get('/travel/get-all-travelforms');
            setBespokeList(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                <BespokeList data={bespokeList} fetchData={fetchData} />
            </div>
        </div>
    );
};

export default Bespoke;
