
const initialState = {
    enquiries: [],
    loading: false,
    error: null,
};

const enquiriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_ENQUIRIES_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'FETCH_ENQUIRIES_SUCCESS':
            return {
                ...state,
                enquiries: action.payload,
                loading: false,
                error: null,
            };
        case 'FETCH_ENQUIRIES_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default enquiriesReducer;
