import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './country-page.scss';
import Axios from '../../api';

const CountryPage = () => {
    const [isUsingImageUrl, setIsUsingImageUrl] = useState(true);
    const [carouselImageFile, setCarouselImageFile] = useState(null);
    const [formData, setFormData] = useState({
        country: '',
        overviewlefth: '',
        overviewrighth: '',
        itineryheading: '',
        carousalimages: [],
        listing: [
            {
                image: '',
                heading: '',
                text: '',
                destination: '',
                duration: '',
                interests: '',
                priceperperson: '',
            },
        ],
    });
    const [imageFiles, setImageFiles] = useState([]);
    const [imageURL, setImageURL] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [itineraryDayData, setItineraryDayData] = useState([
    ]);

    const handleItineraryInputChange = (e, field, index) => {
        const updatedItinerary = [...itineraryDayData];
        updatedItinerary[index][field] = e.target.value;
        setItineraryDayData(updatedItinerary);
    };

    const handleAddDay = () => {
        setItineraryDayData([
            ...itineraryDayData,
            {
                day: itineraryDayData.length + 1,
                location: '',
                activities: '',
            },
        ]);
    };

    const handleRemoveDay = (index) => {
        const updatedItinerary = [...itineraryDayData];
        updatedItinerary.splice(index, 1);
        setItineraryDayData(updatedItinerary);
    };

    const handleInputChange = (e, field, index) => {
        if (field === 'imageURL') {
            setImageURL(e.target.value);
        } else if (field === 'carouselImageURL') {
            const updatedCarousalImages = [...formData.carousalimages];
            updatedCarousalImages[index] = { type: 'url', value: e.target.value };
            setFormData({ ...formData, carousalimages: updatedCarousalImages });
        } else if (index === undefined) {
            setFormData({ ...formData, [field]: e.target.value });
        } else {
            const updatedListings = [...formData.listing];
            updatedListings[index] = { ...updatedListings[index], [field]: e.target.value };
            setFormData({ ...formData, listing: updatedListings });
        }
    };

    const handleAddListing = () => {
        setFormData({
            ...formData,
            listing: [
                ...formData.listing,
                {
                    image: '',
                    heading: '',
                    text: '',
                    destination: '',
                    duration: '',
                    interests: '',
                    priceperperson: '',
                },
            ],
        });
    };

    const handleRemoveListing = (index) => {
        const updatedListings = [...formData.listing];
        updatedListings.splice(index, 1);
        setFormData({ ...formData, listing: updatedListings });
    };

    const handleToggleImageInput = (useImageUrl) => {
        setIsUsingImageUrl(useImageUrl);
    };

    const addCarousalImage = async () => {
        if (isUsingImageUrl) {
            // Add the image URL to the carousalimages array
            setFormData((prevData) => ({
                ...prevData,
                carousalimages: [
                    ...prevData.carousalimages,
                    { type: 'url', value: '' },
                ],
            }));
            setImageURL('');
        } else {
            // Add the uploaded image file to the carousalimages array
            setFormData((prevData) => ({
                ...prevData,
                carousalimages: [
                    ...prevData.carousalimages,
                    { type: 'file', value: '' },
                ],
            }));
            setImageFile(null);
        }
    };

    const handleRemoveCarousalImage = (index) => {
        const updatedImages = [...formData.carousalimages];
        updatedImages.splice(index, 1);
        setFormData({ ...formData, carousalimages: updatedImages });
    };

    const handleFileInputChange = (e) => {
        const files = e.target.files;
        setImageFiles([...imageFiles, ...files]);
    };

    const handleSubmit = async () => {
        try {
            const response = await Axios.post('/api/countries/add-country', {
                ...formData,
                itineraryDayData,
            });

            if (response.status >= 200 && response.status < 300) {
                toast.success('Data submitted successfully', { position: toast.POSITION.TOP_RIGHT });
                setFormData({
                    country: '',
                    overviewlefth: '',
                    overviewrighth: '',
                    itineryheading: '',
                    carousalimages: [],
                    listing: [
                        {
                            image: '',
                            heading: '',
                            text: '',
                            destination: '',
                            interests: '',
                            duration: '',
                            priceperperson: '',
                        },
                    ],
                });
                setItineraryDayData([
                    {
                        day: 1,
                        location: '',
                        activities: '',
                    },
                ]);
            } else {
                toast.error('Server error. Please try again later.', { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred. Please try again later.', { position: toast.POSITION.TOP_RIGHT });
        }
    };

    const uploadFilesToCloudinary = async () => {
        try {
            if (imageFiles.length === 0) {
                return;
            }

            const promises = imageFiles.map(async (file) => {
                const formDataToSend = new FormData();
                formDataToSend.append('file', file);

                const cloudinaryResponse = await Axios.post('/api/cloudinary/upload', formDataToSend);
                console.log(cloudinaryResponse)
                return cloudinaryResponse.data[0].secure_url;
            });

            const cloudinaryUrls = await Promise.all(promises);

            setFormData((prevData) => ({
                ...prevData,
                carousalimages: [
                    ...prevData.carousalimages,
                    ...cloudinaryUrls.map((url) => ({ type: 'file', value: url })),
                ],
            }));

            setFormData((prevData) => ({
                ...prevData,
                carousalimages: prevData.carousalimages.filter((url) => url.value !== "")

            }));

            toast.success('Images uploaded successfully.', { position: toast.POSITION.TOP_RIGHT });
        } catch (error) {
            console.error('Error uploading images to Cloudinary:', error);
            toast.error('Failed to upload images. Please try again.', { position: toast.POSITION.TOP_RIGHT });
        } finally {
            // Clear the file input
            setImageFiles([]);
        }
    };

    useEffect(() => {
        if (imageFiles.length > 0) {
            uploadFilesToCloudinary();
        }
    }, [imageFiles]);


    return (
        <div className="country-form">
            <h2>Create a New Country</h2>
            <div className="form-group">
                <label>Country:</label>
                <input type="text" value={formData.country} onChange={(e) => handleInputChange(e, 'country')} />
            </div>
            <div className="form-group">
                <label>Overview Left:</label>
                <textarea value={formData.overviewlefth} onChange={(e) => handleInputChange(e, 'overviewlefth')} />
            </div>
            <div className="form-group">
                <label>Overview Right:</label>
                <textarea value={formData.overviewrighth} onChange={(e) => handleInputChange(e, 'overviewrighth')} />
            </div>
            <div className="form-group">
                <label>Itinerary Heading:</label>
                <input type="text" value={formData.itineryheading} onChange={(e) => handleInputChange(e, 'itineryheading')} />
            </div>

            <div className="listing-group">
                <label>Carousel Images:</label>
                <div className="tab-buttons">
                    <button
                        className={isUsingImageUrl ? 'active' : ''}
                        onClick={() => handleToggleImageInput(true)}
                    >
                        Use Image URL
                    </button>
                    <button
                        className={!isUsingImageUrl ? 'active' : ''}
                        onClick={() => handleToggleImageInput(false)}
                    >
                        Upload Image
                    </button>
                </div>
                <div className="carousel-images-list">
                    {formData.carousalimages.length > 0 && formData.carousalimages.map((image, index) => (
                        <div key={index} className="form-group">
                            {image.type === 'url' && (
                                <input
                                    type="text"
                                    placeholder="Image URL"
                                    value={image.type === 'url' ? image.value : imageURL}
                                    onChange={(e) => handleInputChange(e, 'carouselImageURL', index)}
                                />
                            )}
                            {image.type === 'file' && (
                                <div>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileInputChange}
                                        multiple
                                        name="file"
                                    />
                                    {formData.carousalimages && formData.carousalimages.filter((item, i) => index === i).map((file, index) => (
                                        <div key={index}>
                                            <img
                                                src={file.value}
                                                alt={`Preview ${index}`}
                                                style={{ width: '100px', height: 'auto', marginRight: '10px' }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            <button onClick={() => handleRemoveCarousalImage(index)}>Remove</button>
                        </div>
                    ))}
                </div>
                <button onClick={addCarousalImage}>Add Carousel Image</button>
            </div>

            {formData.listing.map((listing, index) => (
                <div key={index} className="listing-group">
                    <h3>Listing {index + 1}</h3>
                    <div className="form-group">
                        <label>Image URL:</label>
                        <input type="text" value={listing.image} onChange={(e) => handleInputChange(e, 'image', index)} />
                    </div>
                    <div className="form-group">
                        <label>Heading:</label>
                        <input type="text" value={listing.heading} onChange={(e) => handleInputChange(e, 'heading', index)} />
                    </div>
                    <div className="form-group">
                        <label>Description:</label>
                        <textarea value={listing.text} onChange={(e) => handleInputChange(e, 'text', index)} />
                    </div>
                    <div className="form-group">
                        <label>Destination:</label>
                        <input type="text" value={listing.destination} onChange={(e) => handleInputChange(e, 'destination', index)} />
                    </div>
                    <div className="form-group">
                        <label>Interest:</label>
                        <input type="text" value={listing.interests} onChange={(e) => handleInputChange(e, 'interests', index)} />
                    </div>
                    <div className="form-group">
                        <label>Duration:</label>
                        <input type="text" value={listing.duration} onChange={(e) => handleInputChange(e, 'duration', index)} />
                    </div>
                    <div className="form-group">
                        <label>Price per Person:</label>
                        <input type="text" value={listing.priceperperson} onChange={(e) => handleInputChange(e, 'priceperperson', index)} />
                    </div>
                    <button onClick={() => handleRemoveListing(index)}>Remove Listing</button>
                </div>
            ))}


            {/* Itinerary Section */}
            <div className="itinerary-section listing-group">
                <h2>Day-by-Day Itinerary</h2>
                {itineraryDayData.map((day, index) => (
                    <div key={index} className="itinerary-day">
                        <h3>Day {day.day}</h3>
                        <div className="form-group">
                            <label>Location:</label>
                            <input
                                type="text"
                                value={day.location}
                                onChange={(e) => handleItineraryInputChange(e, 'location', index)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Activities:</label>
                            <textarea
                                value={day.activities}
                                onChange={(e) => handleItineraryInputChange(e, 'activities', index)}
                            />
                        </div>
                        <button onClick={() => handleRemoveDay(index)}>Remove Day</button>
                    </div>
                ))}
                <button onClick={handleAddDay}>Add New Day</button>
            </div>

            <button onClick={handleAddListing}>Add New Listing</button>
            <button onClick={handleSubmit}>Submit</button>
        </div>
    );
};

export default CountryPage;
