import "./bespoketable.scss";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Axios from "../../api";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";


const columns = [
  {
    field: "fullName",
    headerName: "Full Name",
    flex: 1,
    minWidth: 150,
    valueGetter: (params) => {
      return `${params.row.firstName} ${params.row.lastName}`;
    },
  },
  {
    field: "email",
    headerName: "Email",
    minWidth: 250,
    flex: 2,
    renderCell: (params) => {
      return <a href={`mailto:${params.value}`}>{params.value}</a>;
    },
  },
  {
    field: "phone",
    headerName: "Phone",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "postalCode",
    headerName: "Postal Code",
    flex: 1,
    minWidth: 150,
  }
];

const BespokeList = ({ data, fetchData }) => {
  const [list, setList] = useState(data);
  const [selectedData, setSelectedData] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [travelWithCheckboxes, setTravelWithCheckboxes] = useState({});


  const handleDelete = async (id) => {

    try {
      await Axios.delete(`/travel/travelForms/${id}`);
      setList(list.filter((item) => item._id !== id));
      setSelectedData(null);
      fetchData();
      toast.success('Item deleted successfully');
    } catch (error) {
      console.error(error);
      toast.error('Error deleting item');
    }
  };

  const handleView = (row) => {
    setSelectedData(row);
    setTravelWithCheckboxes(row.travelForms[0].travelWith);
    setModalOpen(true);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="viewButton" onClick={() => handleView(params.row)}>View</div>

            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row._id)}>
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  const handleCloseModal = () => {
    setModalOpen(false);
  };



  return (
    <>
      <div className="datatable">
        <DataGrid className="datagrid"
          rows={data}
          columns={columns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
          getRowId={(row) => row._id} />
      </div>

      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Enquiry Details</DialogTitle>
        <DialogContent>
          {selectedData && (
            <div className="selectedData">
              <p>Full Name: {`${selectedData.firstName} ${selectedData.lastName}`}</p>
              <p>Email: <a href={`mailto:${selectedData.email}`}>{selectedData.email}</a></p>
              <p>Phone: {selectedData.phone}</p>
              <p>Postal Code: {selectedData.postalCode}</p>
              {selectedData.travelForms && (
                <div>
                  <p>Travel Forms:</p>
                  <ul>
                    <li>Destination: {selectedData.travelForms[0].destination}</li>
                    <li>Travel Start Dates: {selectedData.travelForms[0].startDate}</li>
                    <li>Travel end Dates: {selectedData.travelForms[0].endDate}</li>
                    <li>Group Size: {selectedData.travelForms[0].groupSize}</li>
                    <li>Traveling With: {Object.entries(travelWithCheckboxes).map(([key, value]) => (
                      <div>
                        <input
                          type="checkbox"
                          checked={value}
                        />
                        {key}
                      </div>
                    ))}</li>
                    <li>Travel Duration: {selectedData.travelForms[0].travelDuration}</li>
                    <li>Budget Per Day: {selectedData.travelForms[0].budgetPerDay}</li>
                    <li>Travel Occasion: {selectedData.travelForms[0].travelOccasion}</li>
                    <li>Experiences: {selectedData.travelForms[0].experiences.join(", ")}</li>
                    <li>Accommodation Type: {selectedData.travelForms[0].accommodationType.join(", ")}</li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default BespokeList;
