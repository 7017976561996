import "./newHotel.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import * as React from "react";
import InputChip from "./Inputwidthchip/InputChip";
import AddReview from "./addreveiw/AddReview";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const NewHotel = ({ inputs, title }) => {
  const [uploaded, setUploaded] = useState("");
  const [progress, setProgress] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [hoteldetails, setHotelDetails] = useState({});
  const [mentionTags, setMentionTags] = useState([]);
  const [customerReviews, setCustomerReviews] = useState([]);
  const [sizeOfProducts, setSizeOfProducts] = useState([]);
  const [colorOfProducts, setColorOfProducts] = useState([]);

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setUploaded("uploadreq");

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("images", selectedFiles[i]);
    }

    try {
      const response = await axios.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });

      setUploaded("uploaded");
      setUploadedImages(response.data.imageUrls);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const HandleChange = (e) => {
    setHotelDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const HandleCreateHotel = async (e) => {
    e.preventDefault();

    // console.log({
    //   ...hoteldetails,
    //   images: uploadedImages,
    //   mentionTags,
    //   customerReviews,
    //   colorOfProducts,
    //   sizeOfProducts,
    // });

    try {
      const res = await axios.post("/create-product", {
        ...hoteldetails,
        images: uploadedImages,
        mentionTags,
        customerReviews,
        colorOfProducts,
        sizeOfProducts,
      });
      if (res.data.success) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="lefthotel">
            {uploadedImages.map((imageUrl, index) => (
              <li key={index}>
                <img
                  src={
                    imageUrl
                      ? imageUrl
                      : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                  }
                  alt={`Uploaded Image ${index}`}
                />
              </li>
            ))}
          </div>
          <div className="righthotel">
            <div className="hotelForm">
              <div className="formInput">
                <label>Images:</label>
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  id="img1"
                  required
                />
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>

                  <input
                    type={input.type}
                    required
                    placeholder={input.placeholder}
                    name={input.id}
                    onChange={HandleChange}
                  />
                </div>
              ))}

              <div>
                <InputChip
                  setMentionTags={setMentionTags}
                  label="mentionTags"
                />
              </div>
              <div>
                <InputChip
                  setMentionTags={setSizeOfProducts}
                  label="sizeOfProducts"
                />
              </div>
              <div>
                <InputChip
                  setMentionTags={setColorOfProducts}
                  label="colorOfProducts"
                />
              </div>
              <div>
                <AddReview
                  setCustomerReviews={setCustomerReviews}
                  customerReviews={customerReviews}
                />
              </div>

              <div style={{ width: "70px", height: "70px" }}>
                <CircularProgressbar value={progress} text={`${progress}%`} />
              </div>

              <button
                className="addProductButton"
                onClick={handleUpload}
                disabled={uploaded === "uploaded"}>
                Upload Files
              </button>
              <button
                className="addProductButton"
                disabled={uploaded !== "uploaded"}
                onClick={HandleCreateHotel}>
                Create Product
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHotel;
